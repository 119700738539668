footer.footer{
    margin-top: 3rem;
    padding: 5rem 0;
    background: $grey;
    font-size: 0.9rem;
    color: $black;
    b{
        font-weight: 600;
    }
    h4{
        text-transform: uppercase;
        font-size: 1.3rem;
        font-weight: 900;
        margin-bottom: 2rem;
    }
    .nav{
        a{
            padding: 0;
            color: $black;
            text-transform: uppercase;
            position: relative;
            display: inline-block;
            margin-bottom: 10px;
            &:after{
                content: '';
                position: absolute;
                display: block;
                bottom: -2px;
                height: 1px;
                left: 0;
                width: 100%;
                background: #646464;
                transform-origin: right center;
                transform: scale(0,1);
                transition: transform .25s cubic-bezier(.37,.31,.2,.85);
            }
            &:hover{
              color:#646464;
              &:after{
                transform-origin: left center;
                transform: scale(1,1);
              }
            }
        }
    }
}