#page{
    .parallax{
        height: 70vh; 
        margin-bottom: 4rem;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        .h1{
            font-size: 3rem;
            padding: 1rem 2rem;
            position: absolute;
            margin: 0;
            background: #000;
            color: #fff;
            bottom: 0;
        }
        .date{
            position: absolute;
            top: 0;
            right: 0;
            padding: 1rem;
            text-align: center;
            .day{
                font-size: 3rem;
                color: #000;
                font-weight: 900;
                display: block;
                background: #fff;
                line-height: 1em;
                padding: 1rem 1rem 0.2em;
            }
            .year,
            .month{
                display: inline-block;
                background: $brand-main;
                color: #fff;
                padding: 0.5rem;
                margin: 3px 1px 0;
            }
           
        }
    }
    
    .no-image{
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 2rem;
        margin-top: 2rem;
        h1{
            font-size: 2rem;
            padding: 1rem 0;
            margin: 0;
            color: $black;
        }
        .date{
            text-align: right;
            .year,
            .day{
                font-size: 1rem;
                color: #000;
                font-weight: 900;
                display: inline-block;
                background: #fff;
                line-height: 1em;
                padding: 1rem 1rem 0.2em;
            }
            
            .month{
                display: inline-block;
                background: $brand-main;
                color: #fff;
                padding: 0.5rem;
                margin: 3px 1px 0;
            }
        }
    }
}