#menu-mobile{
    padding: 1rem;
    color: $black;
    font-size: 0.9rem;
    >b {
        font-size: 1.1rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 1rem;
        display: block;
    }
    .navi{
        list-style:none;
        margin: 0 0 2rem;
        padding: 0;
        a{
            color: $black;
            position: relative;
            text-transform: uppercase;
            padding: 0;
            margin-top: 0.4rem;
            display: inline-block;
            &:after{
                content: '';
                position: absolute;
                display: block;
                bottom: -2px;
                height: 1px;
                left: 0;
                width: 100%;
                background: #646464;
                transform-origin: right center;
                transform: scale(0,1);
                transition: transform .25s cubic-bezier(.37,.31,.2,.85);
            }
            &:hover{
              color:#646464;
              &:after{
                transform-origin: left center;
                transform: scale(1,1);
              }
            }
        }
    }
}