.header{
  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding-top: 30px;
  padding-bottom: 30px;
  background: rgba(255,255,255,0.8);
  transition: background .25s cubic-bezier(0.25, 0.8, 0.25, 1),padding .25s cubic-bezier(0.25, 0.8, 0.25, 1);
  // &.scroll{
    background: #fff;
    margin-top: 0 !important;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0 !important;
  // }
  .nav{
    font-size: 0.9rem;
    li{
      margin-left: 4rem;
    }
    a.active{
      font-weight: bold;
      position: relative;
      color: $black;
      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        width: 50%;
        background: $brand-main;
        height: 2px;
        left: 0;
        right: 0;
        transform: translateX(50%);
      }
    }
  }
  .main-nav{
    font-size: 1.00rem;
    font-weight: 100;
    padding-right: 1rem;
    a{
      color: $black;
      position: relative;
      text-transform: uppercase;
      padding: 0;
      &:after{
          content: '';
          position: absolute;
          display: block;
          bottom: -2px;
          height: 1px;
          left: 0;
          width: 100%;
          background: #646464;
          transform-origin: right center;
          transform: scale(0,1);
          transition: transform .25s cubic-bezier(.37,.31,.2,.85);
      }
      &:hover{
        color:#646464;
        &:after{
          transform-origin: left center;
          transform: scale(1,1);
        }
      }
    }
  }
}

// .parallax-main { 
//   /* The image used */
//   background-image: url("../img/parallax.jpg");

//   /* Set a specific height */
//   height: 160px; 

//   /* Create the parallax scrolling effect */
//   background-attachment: fixed;
//   background-position: bottom center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   margin-top: 5rem;
// }


.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 256px;
  min-height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
}

.slideout-menu-left {
  left: 0;
}

.slideout-menu-right {
  right: 0;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  will-change: transform;
  background-color: #FFF; /* A background-color is required */
  min-height: 50vh;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}


#main-page {
  &:before{
    content: '';
    display: block;
    background-color: rgba(0,0,0,0);
    transition: background-color 0.5s ease-in-out;
    cursor: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIyMHB4IiBoZWlnaHQ9IjIwcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0xNSAxTDEgMTVtMTQgMEwxIDEiLz48L3N2Zz4=), auto;
  }
  &.panel-open{
    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      background-color: rgba(0,0,0,.5);
      z-index: 99;
    }
    #MainHeader{
      nav{
        display: none !important;
      }
    }
  }
}

 