#MainSteps{
    
        .step{
            padding: 1rem 1rem 1rem 0rem;
            // border: 1px solid $grey;
             position: relative;
            &:not(.last){
                &:after{
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: -2rem;
                    width: 3rem;
                    height: 3rem;
                    border: 2px solid $black;
                    border-left: 0;
                    border-top: 0;
                    transform: rotate(-45deg) translateY(-50%);
                    
                    // animation: name duration timing-function delay iteration-count direction fill-mode;
                    @include media-breakpoint-down(sm){
                        display: none;
                    }
                }
            }
            &.fi:after{
                animation: borderColor1 2s ease-in-out 2s infinite;
            }
            &.sec:after{
                animation: borderColor2 2s ease-in-out 2s infinite;
            }
            &.th:after{
                animation: borderColor3 2s ease-in-out 2s infinite;
            }
            .title{
                font-size: 1rem;
                font-weight: bold;
                color: $black;
                text-transform: uppercase;
            }
            p{
                font-size: 0.9rem;
                // color: $black;
                margin: 0;
            }
            .step-icon{
                display: none !important;
                font-size: 2rem;
                line-height: 0;
                color: $grey-dark;
                span{
                    font-weight: bold;
                    color: $grey-dark
                }
            }
        }
   
}

@keyframes borderColor1 {
    0%   {opacity: 0.1;}
    33% {opacity: 1;}
    34%   {opacity: 0.1;}
    100%   {opacity: 0.1;}
}
@keyframes borderColor2 {
    0%   {opacity: 0.1;}
    33%   {opacity: 0.1;}
    66% {opacity: 1;}
    67%   {opacity: 0.1;}
    100%   {opacity: 0.1;}
}
@keyframes borderColor3 {
    0%   {opacity: 0.1;}
    66%   {opacity: 0.1;}
    100% {opacity: 1;}
}