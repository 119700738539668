$z-index:9;
/* Color palette Brand-book */

$black: #141414;
$white: #fff;
$green: #00c251;
$green-dark: #009a3e;
$grey: #f6f6f6;
$grey-dark: #e3e3e3;
$grey-darknes: #c4c4c4;
$text: #7f7f7f;

$error: #ed1c24;
$warning: orange;
$info: blue;
$success: $green;

$shadow-default : none;

$txt-color-default: $black;
$txt-color-primary: $black;
$txt-color-secondary: $green;

$border-default-color: $grey-dark;
$border-default: 1px solid $border-default-color;
$border-default-transparent: 1px solid rgba($border-default-color, 0.5);
$border-secondary: 1px solid $grey-dark;

$border-radius-big: 0;
$border-radius-normal : 0;
$border-radius-small : 0;

$bg-default: $white;
$bg-primary: $white;
$bg-secondary: $grey;
$bg-overlay: rgba($black,0.5);

$brand-main: $green;
$brand-secondary: $black;
$brand-primary: $green;

$black-transparent:rgba($black,0.2);

$product_image_ratio: 100%;

/* Text styles */
$font-normal : 14px;
$font-small : 12px;
$font-tiny : 10px;
$h1 : 26px;
$h2 : 24px;
$h3 : 21px;
$h4 : 20px;
$h5 : 18px;
$h6 : 15px;

$font-normal_min : $font-normal - 2;
$h1_min : $h1 - 4;
$h2_min : $h2 - 4;
$h3_min : $h3 - 4;
$h4_min : $h4 - 4;
$h5_min : $h5 - 4;
$h6_min : $h6 - 2;

$v0: 2px; // min
$v1: 5px;
$v2: 10px;
$v3: 15px;
$v4: 20px;
$v5: 25px;
$v6: 30px;
$v7: 45px;
$v8: 60px;
$v9: 90px; //max

$product_height_ratio:123%;


$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);

$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);

$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);

$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);

$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.23, 1, 0.32, 1);

$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(0.19, 1, 0.22, 1);

$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);

$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

$easeInOutFast: cubic-bezier(1,0,0,1);
$authenticMotion: cubic-bezier(.4,0,.2,1);
