#MainBaner{
    background: url(../img/serwis-baner.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 60vh;
    display: flex;
    align-items: center;
    margin: 0;
    @include media-breakpoint-down(sm){
        min-height: 35vh;
    }
    div{
        display: none;
        h2{
            font-size: 3.5rem;
            color: $black;
            text-transform: uppercase;
            font-weight: 900;
            > span{
                display: block;
                &:first-child{
                    font-weight: 100;
                }
            }
        }
    }
  
}