

/*----------  BOOTSTRAP OVERRIDES  ----------*/
$enable-rounded: false !default;

// GRID SETTINGS
$grid-columns:               12 !default;

$grid-gutter-widths: (
  xs: 5%,
  lg: 50px
) !default;

// BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,// iPhone 6plus horizontal has 736, all tablets start with 768.
  lg: 992px,
  xl: 1200px,
  xxl: 1400px // 1520px with margin 5% on each side
) !default;

// CONTAINER MAX-WIDTHS
$container-max-widths: (
  xs: 95%,
  lg: 1200px
) !default;

// CONTAINER MARGINS
$container-standard-margins: (
  xs: 5%,
  lg: 50px,
  max: auto
) !default;

$v: export('js/config/grid-breakpoints.json', $grid-breakpoints);
$v: export('js/config/grid-columns.json', $grid-columns);
$v: export('js/config/grid-gutter-widths.json', $grid-gutter-widths);
$v: export('js/config/container-max-widths.json', $container-max-widths);
$v: export('js/config/container-standard-margins.json', $container-standard-margins);
