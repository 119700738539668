#sectors{
    .item{
        position: relative;
        width: 100%;
        height: 100%;
        display: block;
       
        > div{
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            overflow: hidden;
            color: $black;
            background-color: rgba(255,255,255,.36);
            // background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0.3) 90%, transparent);
            bottom: 0;
            left: 0;
            margin: 0;
            padding: 16px 20px 0px;
            position: absolute;
            right: 0;
            top: auto;
            z-index: 1;
            transition: top 2.3s ease-out;
            text-align: center;
            > span{
                text-transform: uppercase;
                font-size: 1.3rem;
                // letter-spacing: 0.32rem;
                font-weight: 900;
                transition: all .3s;
            }
            
            &:before{
                background-color: rgba(255,255,255,.06);
                // background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0.3) 90%, transparent);
                bottom: 0;
                content: "";
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                z-index: -1;
                transform: scaleY(0);
                transition: transform .3s ease-out;
                transform-origin: bottom;
            }
           
            p{
                height: 0;
                overflow: hidden;
                opacity: 0;
                transform: translate(-1000%);
                transition: transform .4s ease-out, opacity 1.6s ease-out, height .6s ease-out;
                font-size: 0.9rem;
    
            }
        }
       
        &:hover{
            > div{
                top:0;
                &:before{
                    background-color: rgba(255,255,255,.8);
                    transform: scaleY(1);
                    transition: transform .5s ease-out;
                    transform-origin: bottom;
                }
                > span{
                    font-size: 2rem;
                }
                p{
                    opacity: 1;
                    height: auto;
                    transform: scaleY(1);
                }
            }
          
        }
    }
}
img{
    max-width: 100%;
}