#MainCategories{
    .title{
        color: $black;
        h2{
            font-size: 1.5rem;
            font-weight: bold;
            span{
                font-weight: 100;
            }
        }
    }
    .btn-link{
        font-weight: 500;
    }
    article{
        padding: 0.5rem;
    }
}