body{
    .select2-container--default 
    {
        .select2-selection--multiple,
        .select2-selection--single {
            background-color: #fff;
            border: 1px solid #d1d1d1;
            border-radius: 0;
            height: 38px;
            display: flex;
            align-items: center;
            .select2-selection__rendered {
                display: flex;
                .select2-selection__clear{
                    margin-right: 10px;
                }
            }
        }
    }
   
}