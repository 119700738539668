@import url('https://fonts.googleapis.com/css?family=Hind:100,300,400,500,600,700&subset=latin-ext');

html, body{
    margin: 0;
    color: $text;
    font-family: 'Hind', sans-serif;
    width: 100%;
    overflow-x: hidden;
}
a{
    color: $text;
    &:hover{
        color: $black;
        text-decoration: none;
    }
}
p a {
    text-decoration: underline;
}
// .container-fluid{
//     max-width: 1500px;
// }

section{
    margin-top: 5rem;
    @include media-breakpoint-down(sm){
        margin-top: 3rem
    }
}
.btn{
    cursor: pointer;
}
.bg-white{ background: $white }
.bg-black{ background: $black }
.text{
    &-brand{
        color: $brand-main;
    }
}