.main-h{
    font-weight: 900;
    color: $black;
    text-transform: uppercase;
    margin-bottom: 3rem;
    span{
        display: flex;
        font-weight: 100;
    }
    &.h2{
        font-size: 2rem;
        @include media-breakpoint-down(sm){
            font-size: 1.5rem;
        }
    }
}