.knowledges{
    .title{
        color: $black;
        h2, .h2{
            font-size: 1.2rem;
            font-weight: bold;
            text-transform: uppercase;
            span{
                font-weight: 100;
            }
        }
    }
    p{
        font-size: 0.9rem;
        margin: 0;
    }
    .btn-link{
        font-weight: 500;
    }
    article{
        padding: 0 1rem;
    }
}
#MainKnowledge {
    article{
        padding: 0;
        margin-bottom: 2rem;
        @include media-breakpoint-up(sm){
            padding: 0 1rem;
            margin-bottom: 0;
        }
    }
}