#MainOpinions{
    .main-title{
        margin-top: 2rem;
        color: $black;
        text-transform: uppercase
    }
    .opinions{
        .opinion{
            .icon{
                max-width: 60px;
            }
            .title{
                font-size: 1.4rem;
                font-weight: bold;
                color: $black;
            }
            .date{
                font-size: 0.8rem;
            }
        }
        button.slick-arrow {
            position: absolute;
            left: -90px;
            top:30%;
            opacity:1;
            
        }
        
        button.slick-prev.slick-arrow {
            transform: translateY(-30px);
        }
        button.slick-next.slick-arrow {
            transform: translateY(30px);
        }
    }
    
}