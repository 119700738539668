/** defaulf values */

@import "_bootstrap-config";

@mixin fluid-type-min-max($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media screen and (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media screen and (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($properties, $min-value, $max-value) {

  @include fluid-type-min-max($properties, map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), $min-value, $max-value)
}

@mixin fontAwesome {
  &:before{
      font-family: FontAwesome;
      font-weight: normal;
      font-style: normal;
      text-decoration: inherit;
      -webkit-font-smoothing: antialiased;
      @content;
  }
}

@mixin fontAwesomeAfter {
  &:after{
      font-family: FontAwesome;
      font-weight: normal;
      font-style: normal;
      text-decoration: inherit;
      -webkit-font-smoothing: antialiased;
      @content;
  }
}

@mixin placeholder {

  $selector: '';
  $prefixes: (
      moz: "::-moz",
      webkit: "::-webkit",
      ie: ":-ms"
  );
  @each $prop, $value in $prefixes {
      @if $prop != "moz" {
          $selector: #{$value}-input-placeholder;
      } @else {
          $selector: #{$value}-placeholder;
      }
      @if & {
          &#{$selector} {
              @content;
          }
      } @else {
          #{$selector} {
              @content;
          }
      }
  }
  &::placeholder {
    @content;
  }
}

@mixin reset-button {
  margin: 0;
  padding: 0;
  line-height: 1em;
  border:none;
  background: transparent;
  appearance: none;
  outline: none;
  cursor: pointer;
}
