.overlay,
#overlay{
    background: $bg-overlay;
    width: 200%;
    width: 200vw;
    height: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -50%;
    left: -50vw;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    // transition: opacity 0.7s $easeInBack;
    cursor: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIyMHB4IiBoZWlnaHQ9IjIwcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0xNSAxTDEgMTVtMTQgMEwxIDEiLz48L3N2Zz4=), auto;
    &.active{
        opacity: 1;
        visibility: visible;
        z-index: 101;
    }
}