#MainForms{
    .content-box {
        background: $grey-dark;
        padding: 3rem;
        @include media-breakpoint-down(sm){
            padding: 2rem 1rem;
        }
        > .title{
            font-size: 1.5rem;
            text-transform: uppercase;
            line-height: 1.3rem;
            color: $black;
            b{
                display: block;
            }
        }
        > .desc{
            font-size: 10px;
        }
    }
}