#RegisterForm{
    position: fixed;
    top: 50%;
    left:50%;
    width:85vw;
    max-width: 900px;
    z-index: 200;
    margin: 0;
    transform: translateX(-50%) translateY(-50%);
    @media all and (max-height:600px){
        top: 30px;
        transform: translateX(-50%);
    }
    .form-group {
        margin-bottom: 0.5rem;
    }
    
    .step {
        max-height: 70vh;
        overflow: hidden;
        overflow-y: auto;
        @include media-breakpoint-down(md){
            max-height: 60vh;

            .col-form-label {
                font-weight: bold;
            }
        }
    }
    #step5{
        font-size: 0.9rem;
        color: $black;
    }
    #RegisterFormFooter{
        border-top: $border-default;
        color: $black;
        margin-top: 1rem;
        padding-bottom: 1rem;
        padding-top: 1rem;
        text-transform: uppercase;
        font-size: 0.9rem;
    }
    #RegisterFormHeader{
        color: $black;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: $border-default;
        margin-bottom: 1rem;
        @media all and (max-height:600px){
            .col-10{    
                flex: 0 0 70%;
                max-width: 70%;        
                > *{
                    display: none
                }
                &:before{
                    content: 'Formularz serwisowy'
                }
             }
            .col-2{
                flex: 0 0 30%;
                max-width: 30%;
            }
        }
    }
    #RegisterFormTitle{
        font-size: 1.2rem;
        line-height: 1rem;
        text-transform: uppercase;
        font-weight: 600;
        @include media-breakpoint-down(md){
            font-size: 0.9rem;
        }
      
    }
    #RegisterFormDesc{
        font-size: 0.9rem;
        color: $text;
        text-transform: uppercase;
        @include media-breakpoint-down(md){
            font-size: 0.7rem;
            text-transform: inherit;
        }
      
    }

  
    .choose_shop{
        border: $border-default;
        padding: 1rem;
        margin:10px;
        cursor: pointer;

        
        .check-box{
            width: 30px;
            height: 30px;
            border: $border-default;
            margin-bottom: 10px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            i{
                opacity: 0;
            }
        }

        .title{
            color: $black;
            font-size: 1.2rem;
            b{
                display: block;
            }
        }

        &.active{
            border-width: 2px;
            border-color: $brand-main;
            .check-box i{
                opacity: 1;
            }
        }
    }
   
}


.checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
  
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        &:after {
            content: "";
            position: absolute;
            display: none;

            
        }
    }
  
    &:hover input ~ .checkmark {
        background-color: #ccc;
    }
  
    input:checked ~ .checkmark {
        background-color: $brand-primary;
    }

    &.secodary{
        input:checked ~ .checkmark {
            background-color: $brand-secondary;
        }
    }
  
    input:checked ~ .checkmark:after {
        display: block;
        left: 10px;
        top: 6px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }
  
}

.radiobox {
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
  
    .checkmark {
        position: absolute;
        top: 5px;
        left: 0;
        height: 10px;
        width: 10px;
        // background-color: #eee;
        border: 1px solid #eee;
        border-radius: 50%;
        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }
  
    &:hover input ~ .checkmark {
        background-color: #ccc;
    }
  
    input:checked ~ .checkmark {
        background-color: $black;
    }
  
    input:checked ~ .checkmark:after {
        display: block;
        top: 2px;
        left: 2px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: white;
    }
  
}